var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { "padding-left": "25px", "padding-right": "25px" } },
    [
      _c("h1", [_vm._v("Client Apps Management")]),
      _c(
        "div",
        {
          staticStyle: { display: "flex", "justify-content": "space-between" },
        },
        [
          _c(
            "el-button",
            { attrs: { round: "" }, on: { click: _vm.goToHomeView } },
            [_vm._v("Home")]
          ),
          _vm.hasSysAdminPermission
            ? _c(
                "el-button",
                {
                  attrs: { round: "", type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.registerDialogVisible = true
                    },
                  },
                },
                [_vm._v("Add")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.hasSysAdminPermission
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.pageLoading,
                  expression: "pageLoading",
                },
              ],
            },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.clientApps },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "Name" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "organizationId", label: "Organization ID" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "apiKey", label: "API Key" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "signingPublicKey",
                      label: "Signing Public Key",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "signingKeyAlg",
                      label: "Signing Key Algorithm",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "active", label: "Status" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    scope.row.active
                                      ? "Activated"
                                      : "Deactivated"
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2194747769
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Operations" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.active
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "mini",
                                        type: "danger",
                                        plain: "",
                                      },
                                      on: {
                                        click: () =>
                                          _vm.deactivateConfirmation(scope.row),
                                      },
                                    },
                                    [_vm._v("Deactivate ")]
                                  )
                                : _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "mini",
                                        type: "success",
                                        plain: "",
                                      },
                                      on: {
                                        click: () =>
                                          _vm.activateConfirmation(scope.row),
                                      },
                                    },
                                    [_vm._v("Activate ")]
                                  ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1284249928
                    ),
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    "padding-top": "10px",
                    display: "flex",
                    "justify-content": "flex-end",
                  },
                },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      "page-size": _vm.clientAppsPagination.pageSize,
                      "page-sizes": [5, 10, 20, 50, 100],
                      "current-page": _vm.clientAppsPagination.pageNumber,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.clientAppsPagination.total,
                    },
                    on: {
                      "size-change": _vm.handlePageSizeChange,
                      "current-change": _vm.handlePageNumberChange,
                      "update:pageSize": function ($event) {
                        return _vm.$set(
                          _vm.clientAppsPagination,
                          "pageSize",
                          $event
                        )
                      },
                      "update:page-size": function ($event) {
                        return _vm.$set(
                          _vm.clientAppsPagination,
                          "pageSize",
                          $event
                        )
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _c("div", [_c("div", [_vm._v("Unauthorized")])]),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.registerDialogVisible },
          on: {
            "update:visible": function ($event) {
              _vm.registerDialogVisible = $event
            },
          },
        },
        [
          _c("client-app-register", {
            attrs: { registerDialogVisible: _vm.registerDialogVisible },
            on: {
              "update:registerDialogVisible": function ($event) {
                _vm.registerDialogVisible = $event
              },
              "update:register-dialog-visible": function ($event) {
                _vm.registerDialogVisible = $event
              },
              registered: _vm.handleClientAppRegistered,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }